@tailwind base;
@tailwind components;
@tailwind utilities;

* {
  font-family: "DM Mono", monospace;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  /* outline: deepskyblue dashed 1px !important; */
}
